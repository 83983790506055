






























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { cloneDeep } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Field from '@/components/field.vue';
import ProspectService from '@/services/crm/prospect.service';
import ProspectModel from '@/models/crm/prospect.model';
import { FormHelper } from '@/utils/helpers/form-helper';

@Component({
  components: {
    Field,
  },
})
export default class CrmChatProspectForm extends Vue {
  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  formIsValid = true;

  model: ProspectModel = plainToClass(ProspectModel, {
    codProspect: null,
    cnpj: null,
    fantasia: null,
    razaoSocial: null,
    contato: null,
    ddi: '55',
    telefone: null,
  });

  rules = {
    cnpj: [ValidationRules.required, ValidationRules.cnpj],
    fantasia: [ValidationRules.required],
    razaoSocial: [ValidationRules.required],
    contato: [ValidationRules.required],
    telefone: [ValidationRules.required],
  };

  telephoneMaskActive = false;

  @Prop()
  defaultTelephone!: string;

  mounted(): void {
    this.model.telefone = this.defaultTelephone;

    setTimeout(() => {
      this.telephoneMaskActive = true;
    }, 150);
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        const model = cloneDeep(this.model);
        model.cnpj = model.cnpj.replace(/\D/g, '');
        model.telefone = model.telefone.replace(/\D/g, '');
        const result = await this.prospectService.create(model);

        this.model.codProspect = result.toString();

        this.$notify.success(this.$t('crm.prospectForm.successfullySave'));

        this.$emit('complete', this.model);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  @Emit('cancel')
  onCancel(): void {
    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  get telephoneMask(): string | boolean {
    const parsedNumber = (this.model.telefone || '').replace(/\D/g, '');
    if (parsedNumber.length > 10) {
      return '(##) #####-####';
    }
    return '(##) ####-####';
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.prospectFormContainer,
      canCancel: false,
    });
  }
}
