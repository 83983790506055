





























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { flatten, padStart, random, sortBy } from 'lodash';
import { LoaderComponent } from 'vue-loading-overlay';
import DashboardUpload from '@/components/dashboard-upload.vue';
import { IUploadedFile } from '@/interfaces/uploaded-file.interface';
import UploadService from '@/services/crm/upload.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import dayjs from '@/plugins/dayjs';
import ClientModel from '@/models/crm/client.model';
import { AttachmentTypeEnum } from '@/enums/crm/attachment-type.enum';
import AttachmentService from '@/services/crm/attachment.service';
import AttachmentModel from '@/models/crm/attachment.model';
import DataGridFilter from '@/components/data-grid-filter.vue';
import { ArrayHelper } from '@/utils/helpers/array-helper';
import { IKeyValue } from '@/interfaces/key-value.interface';
import CrmAttachmentsViewerDetailsDialog from '@/components/crm/attachments-viewer-details-dialog.vue';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import { ClientTypeEnum } from '@/enums/client-type.enum';

type UploadFilterConfig = {
  keyword: string | undefined;
  type: AttachmentTypeEnum | null;
};

@Component({
  components: {
    DashboardUpload,
    DataGridFilter,
    ConfirmationDialog,
    CrmAttachmentsViewerDetailsDialog,
  },
})
export default class CrmAttachmentsViewer extends Vue {
  @inject(InjectionIdEnum.CrmUploadService)
  protected uploadService!: UploadService;

  @inject(InjectionIdEnum.CrmAttachmentService)
  protected attachmentService!: AttachmentService;

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
  };

  showUploader = false;

  showDetails = false;

  activeAttachment: AttachmentModel | null = null;

  uploadEndpoint = '';

  uploadHash = CrmAttachmentsViewer.getHash();

  attachmentType: AttachmentTypeEnum = AttachmentTypeEnum.Frontage;

  filters: UploadFilterConfig = {
    keyword: undefined,
    type: null,
  };

  filterTypeOptions: IKeyValue<string | null>[] = [
    {
      code: null,
      name: `${this.$t('global.all')}`,
    },
    {
      code: AttachmentTypeEnum.Frontage,
      name: `${this.$t(`crm.attachmentsViewer.type.${AttachmentTypeEnum.Frontage}`)}`,
    },
    {
      code: AttachmentTypeEnum.Visitation,
      name: `${this.$t(`crm.attachmentsViewer.type.${AttachmentTypeEnum.Visitation}`)}`,
    },
    {
      code: AttachmentTypeEnum.Order,
      name: `${this.$t(`crm.attachmentsViewer.type.${AttachmentTypeEnum.Order}`)}`,
    },
    {
      code: AttachmentTypeEnum.Other,
      name: `${this.$t(`crm.attachmentsViewer.type.${AttachmentTypeEnum.Other}`)}`,
    },
  ];

  fileTypeIcons = {
    video: 'mdi-video',
    audio: 'mdi-headphones',
    pdf: 'mdi-adobe-acrobat',
    unknown: 'mdi-file',
  };

  attachments: AttachmentModel[] = [];

  @Prop({ required: true })
  client!: ClientModel;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  @Prop()
  canRemoveAttachments!: boolean;

  async mounted(): Promise<void> {
    this.uploadEndpoint = this.uploadService.getUploadUrl();

    const loader = this.setBusyLoader();
    try {
      const loadTasks = [this.loadFrontageAttachments(), this.loadOtherAttachments(), this.loadVisitationAttachments()];

      if (this.clientType === ClientTypeEnum.Client) {
        loadTasks.push(this.loadOrderAttachments());
      }

      const result = await Promise.all(loadTasks);
      this.attachments = sortBy(flatten(result), 'creationDate');
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  async loadFrontageAttachments(): Promise<AttachmentModel[]> {
    const clientId = this.clientType === ClientTypeEnum.Prospect ? this.client.codCliente : this.client.cnpjCpf;

    return (await this.attachmentService.getFrontageAttachments(clientId, this.clientType)).map((x) => {
      const item = x;
      item.type = AttachmentTypeEnum.Frontage;
      if (this.clientType !== ClientTypeEnum.Prospect) {
        item.path = this.uploadService.getDownloadUrl(item.path);
      }
      return item;
    });
  }

  async loadVisitationAttachments(): Promise<AttachmentModel[]> {
    const clientId = this.clientType === ClientTypeEnum.Prospect ? this.client.codCliente : this.client.cnpjCpf;

    return (await this.attachmentService.getVisitationAttachments(clientId, this.clientType)).map((x) => {
      const item = x;
      item.type = AttachmentTypeEnum.Visitation;
      return item;
    });
  }

  async loadOrderAttachments(): Promise<AttachmentModel[]> {
    const clientId = this.clientType === ClientTypeEnum.Prospect ? this.client.codCliente : this.client.cnpjCpf;

    return (await this.attachmentService.getOrderAttachments(clientId, this.clientType)).map((x) => {
      const item = x;
      item.type = AttachmentTypeEnum.Order;
      return item;
    });
  }

  async loadOtherAttachments(): Promise<AttachmentModel[]> {
    const clientId = this.clientType === ClientTypeEnum.Prospect ? this.client.codCliente : this.client.cnpjCpf;

    return (await this.attachmentService.getOtherAttachments(clientId, this.clientType)).map((x) => {
      const item = x;
      item.type = AttachmentTypeEnum.Other;
      if (this.clientType !== ClientTypeEnum.Prospect) {
        item.path = this.uploadService.getDownloadUrl(item.path);
      }
      return item;
    });
  }

  onOpenUploader(type: AttachmentTypeEnum): void {
    this.uploadHash = CrmAttachmentsViewer.getHash();
    this.attachmentType = type;
    this.showUploader = true;
  }

  onCloseUploader(): void {
    this.showUploader = false;
  }

  onShowAttachment(attachment: AttachmentModel): void {
    this.activeAttachment = attachment;
    this.showDetails = true;
  }

  async onDeleteAttachment(attachment: AttachmentModel): Promise<void> {
    this.beforeDeleteConfirmation(`${this.$t('global.youAreSureDeleteRecord')}`, async (accept: boolean) => {
      if (accept) {
        const currentList = this.attachments;

        try {
          this.attachments = this.attachments.filter((x) => x.id !== attachment.id);

          if (attachment.type === AttachmentTypeEnum.Frontage) {
            await this.attachmentService.deleteFrontage(attachment.id, this.clientType);
          } else {
            await this.attachmentService.deleteOther(attachment.id, this.clientType);
          }
        } catch (err) {
          this.attachments = currentList;

          this.$notify.error(err && (err as Error).message);
        }
      }
    });
  }

  async onUploadFiles(files: IUploadedFile[]): Promise<void> {
    const attachments = files.map((upload) => {
      const model = new AttachmentModel();

      model.clientId = this.clientId;
      model.path = upload.path;
      model.description = upload.description || '';
      model.name = upload.filename;

      return model;
    });

    try {
      let result: AttachmentModel[];
      if (this.attachmentType === AttachmentTypeEnum.Frontage) {
        result = (await this.attachmentService.saveFrontage(attachments, this.clientType)).map((x) => {
          const item = x;
          item.type = AttachmentTypeEnum.Frontage;

          if (this.clientType !== ClientTypeEnum.Prospect) {
            item.path = this.uploadService.getDownloadUrl(item.path);
          }

          return item;
        });
      } else {
        result = (await this.attachmentService.saveOther(attachments, this.clientType)).map((x) => {
          const item = x;
          item.type = AttachmentTypeEnum.Other;
          item.path = this.uploadService.getDownloadUrl(item.path);
          return item;
        });
      }

      this.attachments = [...this.attachments, ...result];
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  beforeDeleteConfirmation(message: string, onChoice: CallableFunction): void {
    this.dialogConfig.confirmation.message = message;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.onChoice = onChoice;
    this.dialogConfig.confirmation.show = true;
  }

  get filterTypeOptionsByClientType(): IKeyValue<string | null>[] {
    return this.filterTypeOptions.filter((x) => x.code !== AttachmentTypeEnum.Order);
  }

  get clientId(): string {
    return this.clientType === ClientTypeEnum.Prospect ? this.client.codCliente : this.client.cnpjCpf;
  }

  get filteredAttachments(): AttachmentModel[] {
    let filteredItems = this.attachments;

    if (this.filters.keyword) {
      const columnsToSearch = ['name', 'description'];
      filteredItems = ArrayHelper.filterByKeyword(filteredItems, columnsToSearch, this.filters.keyword);
    }

    if (this.filters.type) {
      filteredItems = filteredItems.filter((item) => item.type === this.filters.type);
    }

    return filteredItems;
  }

  get fileTypes(): IKeyValue {
    const mappedTypes: IKeyValue = {};

    this.filteredAttachments.forEach((file) => {
      const filename = file.name || file.path;

      const regexp = new RegExp(/\.[0-9a-z]{1,5}$/i);
      const regexpMatch = filename.match(regexp) || [];

      const typeMatrix = {
        '.jpg': 'image',
        '.jpeg': 'image',
        '.gif': 'image',
        '.png': 'image',
        '.mp4': 'video',
        '.pdf': 'pdf',
        '.mp3': 'audio',
      };

      mappedTypes[`${file.type}_${file.id}`] = typeMatrix[regexpMatch[0]] || 'unknown';
    });

    return mappedTypes;
  }

  get uploadTitle(): string {
    return `${this.$t('crm.attachmentsViewer.dialog.dashboardUpload.title', {
      area: this.$t(`crm.attachmentsViewer.type.${this.attachmentType}`),
    })}`;
  }

  get activeFilters(): number {
    let active = 0;
    const filterKeys = Object.keys(this.filters);

    filterKeys.forEach((key) => {
      switch (key) {
        default:
          if (this.filters[key]) {
            active += 1;
          }
      }
    });

    return active;
  }

  static getHash(): string {
    return `${dayjs().format('HHmmssDD')}${padStart(random(1, 999).toString(), 3, '0')}`;
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.attachmentsViewerContainer,
      canCancel: false,
    });
  }
}
