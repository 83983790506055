


























































































































































import { Vue, Component, Watch, ProvideReactive } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { Route } from 'vue-router';
import VueSocketIO from 'vue-socket.io';
import CrmAppBar from '@/components/crm/app-bar.vue';
import { INavData } from '@/interfaces/nav-data.interface';
import ClientModel from '@/models/crm/client.model';
import ClientService from '@/services/crm/client.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import RouterService from '@/services/router.service';
import CrmClientSearchField from '@/components/crm/client-search-field.vue';
import NavBar from '@/components/nav-bar.vue';
import ContactService from '@/services/crm/contact.service';
import AttendantService from '@/services/attendant.service';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import ContentDialog from '@/components/content-dialog.vue';
import CrmEmailView from '@/components/crm/email-view.vue';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import CrmAdvancedSearch from '@/components/crm/advanced-search.vue';
import CrmAttachmentsViewer from '@/components/crm/attachments-viewer.vue';
import SessionService from '@/services/session.service';
import CrmChat from '@/components/crm/chat.vue';
import ConversationService from '@/services/crm/conversation.service';
import ConversationUserPermissionsModel from '@/models/crm/conversation-user-permissions.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ConfirmationDialog from '@/components/confirmation-dialog.vue';
import CrmProspectForm from '@/components/crm/prospect-form.vue';
import ProspectModel from '@/models/crm/prospect.model';
import SettingsModel from '@/models/crm/settings.model';
import SettingsService from '@/services/crm/settings.service';
import CrmAddToServiceQueueForm from '@/components/crm/add-to-service-queue-form.vue';
import { ICreateConversation } from '@/interfaces/crm/create-conversation.interface';
import { ConversationContactStatusEnum } from '@/enums/crm/conversation-contact-status.enum';

@Component({
  components: {
    CrmAppBar,
    NavBar,
    ContentDialog,
    ConfirmationDialog,
    CrmProspectForm,
    CrmEmailView,
    CrmClientSearchField,
    CrmAdvancedSearch,
    CrmAttachmentsViewer,
    CrmChat,
    CrmAddToServiceQueueForm,
  },
})
export default class CrmIndex extends Vue {
  @inject(InjectionIdEnum.SessionService)
  private sessionService!: SessionService;

  @inject(InjectionIdEnum.AttendantService)
  private attendantService!: AttendantService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.RouterService)
  private routerService!: RouterService;

  @inject(InjectionIdEnum.CrmContactService)
  private contactService!: ContactService;

  @inject(InjectionIdEnum.CrmConversationService)
  private conversationService!: ConversationService;

  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  @ProvideReactive('activeClient')
  activeClient: ClientModel | null = null;

  @ProvideReactive('userContactInfo')
  userContactInfo: UserContactInfo | null = null;

  @ProvideReactive('conversationUserPermission')
  conversationUserPermission: ConversationUserPermissionsModel | null = null;

  @ProvideReactive('clientType')
  clientType = this.sessionService.clientType;

  @ProvideReactive('settings')
  settings: SettingsModel | null = null;

  // userSettings!: UserSettingsModel;

  internalClientType = this.sessionService.clientType;

  mainMenuData: INavData[] = [
    {
      name: `${this.$t('crm.navigation.attendance')}`,
      action: this.onOpenAttendance,
    },
    {
      name: `${this.$t('crm.navigation.attachments')}`,
      action: this.onOpenAttachments,
    },
    {
      name: `${this.$t('crm.navigation.sendEmail')}`,
      action: this.onSendEmail,
    },
  ];

  secondaryMenuData: INavData[] = [
    {
      children: [
        {
          name: `${this.$t('crm.navigation.dashboard', { clientType: this.$t(`crm.${this.clientType}`) })}`,
          icon: 'mdi-card-account-details-outline',
          route: 'CrmDashboard',
        },
        {
          name: `${this.$t('crm.navigation.financialInformation')}`,
          icon: 'mdi-currency-usd',
          route: 'CrmFinancialInformations',
        },
        {
          name: `${this.$t('crm.navigation.calendar')}`,
          icon: 'mdi-calendar-month-outline',
          route: 'CrmCalendar',
        },
        {
          name: `${this.$t('crm.navigation.attendances')}`,
          icon: 'mdi-headset',
          route: 'CrmAttendance',
        },
        {
          name: `${this.$t('crm.navigation.processes')}`,
          icon: 'mdi-sitemap',
          route: 'CrmProcesses',
        },
        {
          name: `${this.$t('crm.navigation.orders')}`,
          icon: 'mdi-cart',
          route: 'CrmOrders',
        },
        {
          name: `${this.$t('crm.navigation.sales')}`,
          icon: 'mdi-finance',
          route: 'CrmSales',
        },
        {
          name: `${this.$t('crm.navigation.visitsMade')}`,
          icon: 'mdi-store',
          route: 'CrmVisitsMade',
        },
        {
          name: `${this.$t('crm.navigation.clientsManagement')}`,
          icon: 'mdi-list-status',
          route: 'CrmClientsManagement',
        },
        {
          name: `${this.$t('crm.navigation.emails')}`,
          icon: 'mdi-email-outline',
          route: 'CrmEmails',
        },
        {
          name: `${this.$t('crm.navigation.chats')}`,
          icon: 'mdi-chat',
          route: 'CrmChats',
        },
      ],
    },
  ];

  dialogConfig: IKeyValue<IDialogConfig> = {
    confirmation: {
      message: '',
      color: '',
      show: false,
      onChoice: () => {},
    },
    sendEmail: {
      show: false,
    },
    advancedSearch: {
      show: false,
    },
    attachments: {
      show: false,
    },
    prospect: {
      show: false,
    },
    attendance: {
      show: false,
    },
  };

  activeRoute: string | null | undefined = null;

  socketEvaluated = false;

  createConversation: ICreateConversation | null = null;

  @Watch('$route')
  watchRoute(value: Route): void {
    if (value.name === 'CrmDashboard' && this.sessionService.activeClient) {
      this.activeRoute = value.name;
      if (this.sessionService.clientType !== this.internalClientType) {
        this.clientType = this.sessionService.clientType;
        this.internalClientType = this.clientType;
      }

      const client = this.clientType === ClientTypeEnum.Client
        ? this.sessionService.activeClient.cnpjCpf
        : this.sessionService.activeClient.codCliente;
      if (!client) {
        this.activeClient = null;
      } else {
        this.activeClient = this.sessionService.activeClient;
        this.onClientSelected(this.activeClient);
      }
    }
  }

  async mounted(): Promise<void> {
    const currentRoute = this.routerService.route();
    const routeParams = currentRoute && currentRoute.params;
    const routeQuery = currentRoute && currentRoute.query;

    if (routeParams.previousRoute === 'CrmAttendantPanel') {
      this.activeRoute = 'CrmAttendantPanel';
    } else {
      this.activeRoute = currentRoute.name;
    }

    const loader = this.$loading.show();

    await this.loadSettings();
    await this.loadUserContactInfo();

    const clientId = routeParams?.clientId;
    if (clientId && this.activeClient === null) {
      await this.loadClient(clientId);
    }

    try {
      this.conversationUserPermission = await this.conversationService.getUserPermissions();
      this.settings = await this.settingsService.getSettings();
      this.settings.flagPermiteExcluirAnexos = true;

      const onOpenCreateConversation: string = routeQuery?.onOpenCreateConversation as string;
      if (onOpenCreateConversation) {
        const splittedData = onOpenCreateConversation.split(',');
        const contact = await this.contactService.getContact(parseInt(splittedData[0], 10));

        this.createConversation = {
          waContact: {
            waId: splittedData[1],
            input: splittedData[2],
            status: ConversationContactStatusEnum.Valid,
          },
          contact,
        };
      }
    } catch (error) {
      this.$notify.error(error && (error as Error).message);
    }

    try {
      if (this.sessionService && this.sessionService.socketIOUrl) {
        const debug = this.sessionService.socketIOUrl.indexOf('localhost') > -1;
        const socket = new VueSocketIO({
          debug,
          connection: this.sessionService.socketIOUrl,
        });
        Vue.use(socket);

        this.socketEvaluated = true;
      }
    } catch (error) {
      this.$notify.error(error && (error as Error).message);
    }

    loader.hide();
  }

  onClientTypeChange(): void {
    if (this.activeClient && this.internalClientType !== this.clientType) {
      const isProspect = this.internalClientType === ClientTypeEnum.Prospect;
      const message = isProspect
        ? 'crm.view.index.wouldLikeToChangeToProspect'
        : 'crm.view.index.wouldLikeToChangeToClient';

      this.beforeChangeClientType(`${this.$t(message)}`, async (accept: boolean) => {
        if (accept) {
          this.activeClient = null;
          this.clientType = this.internalClientType;
          this.sessionService.activeClient = new ClientModel();
          this.sessionService.clientType = this.clientType;
          this.routerService.navigate({ name: 'CrmHome' });
        } else {
          this.sessionService.activeClient = new ClientModel();
          this.internalClientType = this.clientType;
        }
      });

      return;
    }

    this.clientType = this.internalClientType;
    this.sessionService.clientType = this.clientType;
    this.routerService.navigate({ name: 'CrmHome' });
  }

  beforeChangeClientType(message: string, onChoice: CallableFunction): void {
    this.dialogConfig.confirmation.message = message;
    this.dialogConfig.confirmation.color = 'red';
    this.dialogConfig.confirmation.onChoice = onChoice;
    this.dialogConfig.confirmation.show = true;
  }

  onClientSelected(client: ClientModel): void {
    if (!client) {
      return;
    }

    this.activeClient = client;
    const clientId = client.type === ClientTypeEnum.Client ? client.cnpjCpf : client.codCliente;

    this.loadClient(clientId);

    this.routerService.navigate({ name: 'CrmDashboard', params: { clientId } });
  }

  onSendEmail(): void {
    this.dialogConfig.sendEmail.show = true;
  }

  onOpenAttachments(): void {
    this.dialogConfig.attachments.show = true;
  }

  onOpenAttendance(): void {
    this.dialogConfig.attendance.show = true;
  }

  onAdvancedSearch(): void {
    this.dialogConfig.advancedSearch.show = true;
  }

  onClickPrevious(): void {
    this.$router.back();
  }

  onAddProspect(): void {
    this.dialogConfig.prospect.show = true;
  }

  onAfterSaveProspect(model: ProspectModel): void {
    this.dialogConfig.prospect.show = false;

    this.loadClient(model.codProspect);

    this.routerService.navigate({ name: 'CrmDashboard', params: { clientId: model.codProspect } });
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  get showAddProspectBtn(): boolean {
    return !!(!this.isBuiltInMode && this.isProspectType);
  }

  get secondaryMenu(): INavData[] {
    if (this.isProspectType) {
      const prospectMenu = ['CrmDashboard', 'CrmCalendar', 'CrmAttendance', 'CrmProcesses', 'CrmEmails', 'CrmChats'];

      return [
        {
          children: (this.secondaryMenuData[0]?.children || []).filter(
            (item) => item.route && prospectMenu.includes(item.route),
          ),
        },
      ];
    }

    return this.secondaryMenuData;
  }

  get attendanceDialogTitle(): string {
    return this.$t('crm.view.index.dialog.attendance.title', {
      clientType: this.$t(`crm.${this.clientType}`).toString().toLowerCase(),
    }).toString();
  }

  get mainTitle(): string {
    return (this.activeClient && (this.activeClient.nomeFantasia || this.activeClient.nome)) || '';
  }

  get disableMenu(): boolean {
    return (!this.activeClient || !this.activeClient.codCliente) && !this.routerService.route().params.clientId;
  }

  get isBuiltInMode(): boolean {
    return this.sessionService && this.sessionService.builtInMode;
  }

  get hideAppBar(): boolean {
    return this.sessionService && this.sessionService.hideAppBar;
  }

  get chatHeight(): string | number {
    let diff = this.showAppBarExtension ? 124 : 64;
    diff = this.isBuiltInMode ? 64 : diff;
    if (this.hideAppBar) {
      diff = 0;
    }
    return `calc(100vh - ${diff}px)`;
  }

  get showAppBarExtension(): boolean {
    return this.activeRoute === 'CrmHome' || this.activeRoute === 'CrmDashboard';
  }

  get showChat(): boolean {
    const permissions = this.conversationUserPermission;
    const hasPermission = permissions?.viewWhatsApp && permissions?.manageWhatsApp;
    const isReady = this.userContactInfo && this.conversationUserPermission && this.socketEvaluated;
    return !!(isReady && hasPermission);
  }

  get showSecondaryMenu(): boolean {
    return this.activeRoute !== 'CrmChatOverview';
  }

  get showPreviousBtn(): boolean {
    return !this.showSecondaryMenu || this.activeRoute === 'CrmAttendantPanel';
  }

  private async loadSettings(): Promise<void> {
    this.settings = await this.settingsService.getSettings();
  }

  /* private async loadUserSettings(): Promise<void> {
    this.userSettings = await this.attendantService.getCrmSettings();
  } */

  private async loadClient(clientId: string): Promise<void> {
    try {
      this.activeClient = await this.clientService.getSummary(clientId, this.clientType);
      this.activeClient.nomeFantasia = (this.activeClient.nomeFantasia || '').trim()
      || (this.activeClient.nome || '').trim();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }

  private async loadUserContactInfo(): Promise<void> {
    try {
      this.userContactInfo = await this.contactService.getLoggedUserContactInfo();
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    }
  }
}
